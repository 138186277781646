import axios from 'axios'
import qs from 'qs'

const BASE_URL = process.env.REACT_APP_REST_BASE_URL

const language = new URLSearchParams(window.location.search).get('lang') || 'en'
const apiKey = new URLSearchParams(window.location.search).get('token')

export const axiosClient = axios.create({
  baseURL: BASE_URL
})

const defaultConfig = {
  headers: {
    'Content-type': 'application/json',
    'Accept-Language': language,
    'player-token': apiKey
  }
}

const bodyFormatter = (data, config) => {
  return data
}

export const getData = ({ url, params, headers = defaultConfig.headers, cancelToken }) => {
  const options = {
    method: 'GET',
    params,
    headers,
    url,
    cancelToken
  }
  return axiosClient(options)
}

export const postData = ({ url, body }, config = defaultConfig) => axiosClient.post(url, bodyFormatter(body, config), config)

export const putData = ({ url, body }, config = defaultConfig) => axiosClient.put(url, qs.stringify(body), config)

export const patchData = ({ url, body }, config = defaultConfig) => axiosClient.patch(url, qs.stringify(body), config)

export const deleteData = (url, data) => axiosClient.delete(url, { data })
