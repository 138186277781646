import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selectedWallet: null,
  userBalance: 0,
  redirectToAfterLogin: '',
  userGameToggleSettings: { music: false, sound: false, animation: true },
  addedFreeBets: { freeBetId: null, count: 0, minMulti: 0 },
  token: '',
  isTokenExpired: false,
  customer: ''
}

const {
  actions: {
    setUserBalance, setUserGameToggleSettings, setUserGameDetail, setSelectedFreeBets, setToken, setIsTokenExpired
  },
  reducer
} = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserBalance: (state, action) => ({
      ...state,
      userBalance: action.payload?.balance,
      selectedWallet: { ...state.selectedWallet, ...action.payload }
    }),
    setUserGameToggleSettings: (state, action) => ({
      ...state,
      userGameToggleSettings: action.payload
    }),
    setUserGameDetail: (state, action) => ({
      ...state,
      trader: action.payload.trader,
      apiKey: action.payload.apiKey,
      currencyDetail: action.payload.currency,
      selectedWallet: { code: action.payload.currency.code, ...state.selectedWallet },
      language: action.payload.language,
      customer: action.payload.customer
    }),
    setSelectedFreeBets: (state, action) => ({
      ...state,
      addedFreeBets: action.payload
    }),
    setToken: (state, action) => ({
      ...state,
      token: action.payload
    }),
    setIsTokenExpired: (state, action) => ({
      ...state,
      isTokenExpired: action.payload
    })
  }
})

export default reducer
export {
  setUserBalance, setUserGameToggleSettings, setUserGameDetail, setSelectedFreeBets, setToken, setIsTokenExpired
}
